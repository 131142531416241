
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Pagination extends Vue {
  @Prop() currentPage!: number
  @Prop() totalPage!: number
  input(page: number) {
    this.$emit('goToPage', page)
  }
}
